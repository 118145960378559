import { Button, Col, Row, Space } from 'antd';
import { Modal, ModalTitle } from 'components/UI/Modal/styled';
import { FilterSettings, QueryNameKey } from 'components/Workspaces/collections';
import {
  Column,
  ISortParam,
  WorkspaceGraphQLField,
  WorkspaceGraphQLObjectField,
} from 'components/Workspaces/General/shared/GeneralWorkspace/collections';
import { FilterItem, useFilters } from 'components/Workspaces/General/shared/GeneralWorkspace/FiltersStore';
import Columns from 'components/Workspaces/General/shared/GeneralWorkspace/shared/Columns';
import { SortColumn } from 'components/Workspaces/General/shared/GeneralWorkspace/SortColumn';
import { decamelize } from 'humps';
import { ExistingWorkspacePreset, WorkspacePreset } from 'interfaces/graphql/workspacePreset';
import { filter, map } from 'lodash-es';
import React, { useState } from 'react';

import Filters from './Filters';
import SplitFilters from './Filters/SplitFilters';
import PresetForm from './PresetForm';
import { useSortColumns } from './useSortColumns';

interface Props {
  attributes: WorkspaceGraphQLField[];
  objectAttributes: WorkspaceGraphQLObjectField[];
  columns: Column[];
  currentPreset: WorkspacePreset;
  customFilters?: FilterSettings[];
  hidePresetFilters?: boolean;
  hideUndefinedColumns?: boolean;
  defaultSort?: ISortParam;
  onCancel: () => void;
  onPresetCreate: (name: string, isPublic: boolean, columns: Column[], sort?: ISortParam) => void;
  onPresetUpdate: (preset: ExistingWorkspacePreset) => void;
  onPresetDelete: (preset: ExistingWorkspacePreset) => void;
  onSubmit: (columns: Column[], sort?: ISortParam) => void;
  queryName: QueryNameKey;
  visible?: boolean;
}

const EditFiltersModal: React.FC<Props> = ({
  attributes,
  objectAttributes,
  columns,
  currentPreset,
  customFilters,
  hidePresetFilters,
  hideUndefinedColumns,
  onCancel,
  onPresetCreate,
  onPresetUpdate,
  onPresetDelete,
  onSubmit,
  queryName,
  visible,
  defaultSort,
}) => {
  const [currentColumns, setCurrentColumns] = useState<Column[]>(columns);

  const filters = useFilters();

  let presetFilters: FilterItem | undefined;
  let additionalFilters: FilterItem | undefined;

  if (hidePresetFilters && currentPreset.id != null && currentPreset.query != null) {
    [presetFilters, additionalFilters] = filters.partition(FilterItem.fromQuery(currentPreset.query));
  } else {
    additionalFilters = filters.deepClone();
  }

  const [presetFiltersClone] = useState(presetFilters);
  const [additionalFiltersClone] = useState(additionalFilters ?? new FilterItem());

  const { sortColumn, sortDirection, sortOptions, handleChangeSort } = useSortColumns({
    sort: defaultSort,
    columns: currentColumns,
  });

  const sort = sortColumn && sortDirection ? { sortBy: decamelize(sortColumn), direction: sortDirection } : undefined;

  const applyFilterChanges = () => {
    if (hidePresetFilters && presetFiltersClone != null) {
      filters.assignRoot(presetFiltersClone.combine(additionalFiltersClone));
    } else {
      filters.assignRoot(additionalFiltersClone);
    }
  };

  const handleFiltersSubmit = () => {
    applyFilterChanges();

    // Sort columns after applying filters and columns.
    const sortedByEnabled = currentColumns.sort((x, y) => {
      // true values first
      return x.enabled === y.enabled ? 0 : x.enabled ? -1 : 1;
    });

    onSubmit(sortedByEnabled, sort);
  };

  const handlePresetCreate = (name: string, isPublic: boolean, columns: Column[]) => {
    applyFilterChanges();
    onPresetCreate(name, isPublic, columns, sort);
  };

  const handlePresetUpdate = (preset: ExistingWorkspacePreset) => {
    applyFilterChanges();

    onPresetUpdate({
      ...preset,
      columns: map(filter(currentColumns, { enabled: true }), 'key'),
      query: filters.toQuery,
      sortColumn: sort?.sortBy,
      sortDirection: sort?.direction,
    });
  };

  return (
    <Modal
      bodyStyle={{ padding: '10px 12px' }}
      destroyOnClose
      footer={null}
      onCancel={onCancel}
      title={<ModalTitle>Edit filters and columns</ModalTitle>}
      open={visible}
      style={{ maxWidth: '1300px' }}
      width="95%"
    >
      <Row gutter={12}>
        <Col xs={24} md={18}>
          {hidePresetFilters ? (
            <SplitFilters
              additionalFilters={additionalFiltersClone}
              attributes={attributes}
              columns={columns}
              customFilters={customFilters}
              hideUndefinedColumns={hideUndefinedColumns}
              objectAttributes={objectAttributes}
              presetFilters={presetFiltersClone}
            />
          ) : (
            <Filters
              attributes={attributes}
              columns={columns}
              customFilters={customFilters}
              filters={additionalFiltersClone}
              hideUndefinedColumns={hideUndefinedColumns}
              objectAttributes={objectAttributes}
            />
          )}
          <Row justify="end" style={{ marginTop: '10px' }}>
            <Button onClick={handleFiltersSubmit} type="primary">
              Apply filters and columns
            </Button>
          </Row>

          <PresetForm
            currentColumns={currentColumns}
            currentPreset={currentPreset}
            onPresetCreate={handlePresetCreate}
            onPresetUpdate={handlePresetUpdate}
            onPresetDelete={onPresetDelete}
            queryName={queryName}
          />
        </Col>
        <Col xs={24} md={6}>
          <Space direction="vertical">
            <Columns columns={currentColumns} onColumnsChange={setCurrentColumns} />
            <SortColumn
              sortOptions={sortOptions}
              onChange={handleChangeSort}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
            />
          </Space>
        </Col>
      </Row>
    </Modal>
  );
};

export default EditFiltersModal;
